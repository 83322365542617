<template>
	<div class="columns is-mobile is-multiline is-variable is-7">
		<div
			v-for="(a, i) in activities"
			:key="i"
			:class="{ 'is-completed': a.completed }"
			class="column is-12-touch is-6-desktop mt-4 mb-1 activity-container"
		>
			<div class="box is-rounded is-flex p-3 is-relative">
				<div class="tile is-6 pr-5">
					<img :src="a.image" alt="image" />
					<div v-if="a.completed" class="activity-completed">
						<div class="green-circle">
							<BaseIcon icon="checkbox-active" :size="3" no-padding />
						</div>
					</div>
				</div>
				<div
					class="tile is-6 is-flex is-flex-direction-column is-justify-content-space-between"
				>
					<div class="title is-5 mb-0 mt-5">{{ a.title }}</div>
					<div class="my-2 has-text-grey-dark pr-5">
						{{ a.description }}
					</div>
					<div class="has-text-right">
						<button
							v-if="a.type === 'video'"
							:class="{ 'is-primary': !a.completed }"
							class="button is-medium"
							@click="completeActivity(a.key)"
						>
							{{ a.button_title }}
						</button>
						<router-link
							v-else-if="a.type === 'route'"
							:to="{ name: a.route }"
							:class="{ 'is-primary': !a.completed }"
							class="button is-medium"
							@click="completeActivity(a.key)"
						>
							{{ a.button_title }}
						</router-link>
						<a
							v-else-if="a.type === 'link'"
							:href="a.link"
							:class="{ 'is-primary': !a.completed }"
							class="button is-medium"
							target="_blank"
							@click="completeActivity(a.key)"
						>
							{{ a.button_title }}
						</a>
					</div>
				</div>
			</div>
		</div>

		<LightModal ref="introVideoModal" :width="800">
			<VimeoVideo v-if="isStudent" video-id="295621010" />
			<VimeoVideo v-else-if="schoolIsPrimarySchool" video-id="673563328" />
			<VimeoVideo v-else video-id="552430591" />
		</LightModal>
	</div>
</template>

<script>
import BaseIcon from '@/components/base/BaseIcon';
import LightModal from '@/components/ui/modal/LightModal';
import VimeoVideo from '@/components/ui/embed/VimeoVideo';
import { mapGetters } from 'vuex';

export default {
	name: 'GetStarted',
	components: { BaseIcon, LightModal, VimeoVideo },
	props: {
		completedActivities: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			completedActivitiesCopy: [],
		};
	},
	computed: {
		...mapGetters('user', ['isTeacher', 'isStudent', 'schoolIsPrimarySchool']),
		activities() {
			const activities = this.isTeacher
				? [
						{
							key: 'welcome',
							title: 'Kom godt i gang',
							description:
								'Marcus viser dig her et par fif til at komme godt fra start.',
							button_title: 'Se video',
							type: 'video',
						},
						{
							key: 'create_class',
							title: 'Opret hold',
							description:
								'Opret dit første hold på Minlæring, så dine elever kan træne i alle vores opgaver.',
							button_title: 'Opret hold',
							type: 'route',
							route: 'school_classes',
						},
						{
							key: 'create_homework',
							title: 'Opret lektie',
							description:
								'Opret din første lektie, hvor du vælger, hvad eleverne skal arbejde med.',
							button_title: 'Opret lektie',
							type: 'route',
							route: 'homework',
						},
						{
							key: 'add_editool',
							title: 'Tilføj Editool',
							description:
								'Få flere muligheder i dit rettearbejde i Word og Docs.',
							button_title: 'Se mere',
							type: 'link',
							link: 'https://www.fejlretter.dk/inspiration-til-dit-rettearbejde',
						},
						{
							key: 'confirm_information',
							title: 'Bekræft oplysninger',
							description:
								'Vær sikker på, at du kan genskabe dit login ved at bekræfte dine oplysninger.',
							button_title: 'Bekræft oplysninger',
							type: 'route',
							route: 'profile',
						},
				  ]
				: [
						{
							key: 'welcome',
							title: 'Kom godt i gang',
							description:
								'Marcus viser dig her et par fif til at komme godt fra start.',
							button_title: 'Se video',
							type: 'video',
						},
						{
							key: 'confirm_information',
							title: 'Bekræft oplysninger',
							description:
								'Vær sikker på, at du kan genskabe dit login ved at bekræfte dine oplysninger.',
							button_title: 'Bekræft oplysninger',
							type: 'route',
							route: 'profile',
						},
				  ];
			activities.forEach((activity, index) => {
				const completedActivity = this.completedActivitiesCopy[activity.key];
				activities[index].image = `/images/dashboard/get-started/${activity.key}.svg`;
				if (completedActivity) {
					activities[index].completed = true;
				}
			});

			return activities;
		},
	},
	created() {
		this.completedActivitiesCopy = this.completedActivities;
	},
	methods: {
		completeActivity(key) {
			if (key === 'welcome') {
				this.completeIntroVideoStep();
			} else if (key === 'add_editool') {
				this.completeEditoolStep();
			}
		},
		completeIntroVideoStep() {
			this.$refs.introVideoModal.show();
			this.logActivity('clicked_intro_video');
			this.completedActivitiesCopy['welcome'] = true;
		},
		completeEditoolStep() {
			this.logActivity('clicked_editool_link');
			this.completedActivitiesCopy['add_editool'] = true;
		},
		logActivity(action) {
			return this.$store.dispatch('activity/logActivity', {
				action: action,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
@import '@/assets/sass/abstracts/mixins';
.activity-container {
	max-width: 750px;
	@include mixin-touch-only {
		margin-left: auto;
		margin-right: auto;
	}
	&.is-completed {
		filter: grayscale(80%);
		opacity: 0.4;
	}
	.box {
		img {
			border-radius: $radius-larger;
			overflow: hidden;
			width: 100%;
		}

		.activity-completed {
			position: absolute;
			background-color: white;
			width: 12%;
			aspect-ratio: 1 / 1;
			bottom: 0;
			left: 0;
			border-top-right-radius: $radius-larger;
			border-bottom-left-radius: $radius-larger;
			overflow: hidden;
			display: flex;
			justify-content: center;
			align-items: center;

			.green-circle {
				border-radius: 50%;
				background-color: $green;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 40%;
				height: 40%;
			}
		}
	}
}
</style>
