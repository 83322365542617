import http from './http';

export function getSchoolClass(schoolId, schoolClassId) {
	return http.get(`/schools/${schoolId}/classes/${schoolClassId}`).then(resp => resp.data.data);
}

export function getSchoolTeachers(schoolId) {
	return http.get(`/schools/${schoolId}/teachers`).then(resp => resp.data.data);
}

export function getSchoolClassLevels(schoolId) {
	return http.get(`/schools/${schoolId}/levels`).then(resp => resp.data.data);
}

export function getSchoolClasses(schoolId, archived = false, bypassImpersonation = false) {
	const options = {
		params: { archived },
		bypassImpersonation,
	};
	return http.get(`/schools/${schoolId}/user/classes`, options).then(resp => resp.data.data);
}

export function getSchoolClassesAll(schoolId, archived = false, bypassImpersonation = false) {
	const options = {
		params: { archived },
		bypassImpersonation,
	};
	return http.get(`/schools/${schoolId}/classes`, options).then(resp => resp.data.data);
}

export function getClassByCode(code) {
	return http.get('/classes/code', { params: { join_code: code } }).then(resp => resp.data.data);
}

export function joinClassByCode(schoolID, classID, code, name, email, password, passwordConfirm) {
	return http
		.post(`/schools/${schoolID}/classes/${classID}/join`, {
			join_code: code,
			name,
			email,
			password,
			password_confirmation: passwordConfirm,
		})
		.then(resp => resp.data);
}

export function joinSchool(schoolId) {
	return http.post(`/schools/${schoolId}/join`).then(resp => resp.data);
}

export function getSchoolClassCreateFlow(schoolId) {
	return http.get(`/schools/${schoolId}/user/classes/create`).then(resp => resp.data.data);
}

export function getSchoolClassCourses(schoolId, schoolClassId) {
	return http.get(`/schools/${schoolId}/classes/${schoolClassId}/courses`).then(resp => resp.data.data);
}

export function getSchoolClassCourseMaterials(schoolId, schoolClassId, courseId) {
	return http
		.get(`/schools/${schoolId}/classes/${schoolClassId}/materials/${courseId}`)
		.then(resp => resp.data.data);
}

export function createSchoolClass(schoolId, data) {
	return http.post(`/schools/${schoolId}/classes`, data).then(resp => resp.data.data);
}

export function updateSchoolClass(schoolId, schoolClassId, data) {
	return http.put(`/schools/${schoolId}/classes/${schoolClassId}`, data).then(resp => resp.data.data);
}

export function deleteSchoolClass(schoolId, schoolClassId) {
	return http.delete(`/schools/${schoolId}/classes/${schoolClassId}`).then(resp => resp.data.data);
}

export function restoreSchoolClass(schoolId, schoolClassId) {
	return http.put(`/schools/${schoolId}/classes/${schoolClassId}/restore`).then(resp => resp.data.data);
}

export function getSchoolClassActions(schoolId, schoolClassId) {
	return http.get(`/schools/${schoolId}/classes/${schoolClassId}/actions`).then(resp => resp.data.data);
}

export function removeStudentFromSchoolClass(schoolId, schoolClassId, studentId) {
	return http
		.delete(`/schools/${schoolId}/classes/${schoolClassId}/students/${studentId}`)
		.then(resp => resp.data.data);
}

export function resetStudentLogin(schoolId, studentId, data) {
	return http.put(`/schools/${schoolId}/students/${studentId}/reset`, data).then(resp => resp.data.data);
}

export function addTeacherToSchoolClass(schoolId, schoolClassId, teacherId) {
	return http.post(`/schools/${schoolId}/classes/${schoolClassId}/teachers`, { teacher_id: teacherId });
}

export function removeTeacherFromSchoolClass(schoolId, schoolClassId, teacherId) {
	return http.delete(`/schools/${schoolId}/classes/${schoolClassId}/teachers/${teacherId}`);
}

export function getSchoolClassUniloginSync(schoolId, schoolClassId, data) {
	return http
		.post(`/schools/${schoolId}/classes/${schoolClassId}/syncfromunilogin`, data)
		.then(resp => resp.data.data);
}

export function addBooksToSchoolClass(schoolId, schoolClassId, bookIds) {
	return http
		.post(`/schools/${schoolId}/classes/${schoolClassId}/books`, { book_ids: bookIds })
		.then(resp => resp.data.data);
}

export function removeBookFromSchoolClass(schoolId, schoolClassId, bookId) {
	return http
		.delete(`/schools/${schoolId}/classes/${schoolClassId}/books/${bookId}`)
		.then(resp => resp.data.data);
}
